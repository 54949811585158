<!--
 * @Author: luoguo
 * @Date: 2023-12-07 10:06:10
 * @LastEditTime: 2024-01-05 22:32:26
 * @LastEditors: luoguo
 * @Description: 
-->
<template>
  <div id="app">
    <Home />
  </div>
</template>

<script>
import Home from "./components/Home.vue";
export default {
  name: "App",
  components: { Home },
  mounted() {},
};
</script>

<style>
body {
  margin: 0;
  padding: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.flex {
  display: flex;
  flex-direction: row;
}
.flex-center {
  align-items: center;
  justify-content: center;
}
.margin-t-16 {
  margin-top: 16px;
}
.margin-t-60 {
  margin-top: 60px;
}
.donwload-text {
  margin-top: 28px;
  font-weight: bold;
  text-align: center;
}
.btn {
  background: #212121;
  color: #ffffff;
  font-size: 14px;
  border: none;
  width: 140px;
  height: 42px;
  border-radius: 3px;
}
.font-16 {
  font-size: 16px;
}
.font-12 {
  font-size: 12px;
}
.wechat-brower {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow-y: hidden;
  background: #c4e3f7;
  z-index: 1000;
}
</style>
