<!--
 * @Author: luoguo
 * @Date: 2023-09-17 23:31:01
 * @LastEditTime: 2024-01-05 22:29:28
 * @LastEditors: luoguo
 * @Description: 
-->
<template>
  <div style="position: relative">
    <img
      :src="require('../assets/home.png')"
      style="width: 100%; height: auto"
    />
    <div
      style="position: absolute; bottom: 0; left: 0; width: 100vw; height: 40px"
      @click="gotoBAJ"
    ></div>
  </div>
</template>

<script>
export default {
  name: "Download",
  data() {
    return {
      config: "",
    };
  },
  computed: {
    showWxBrower() {
      return (
        String(navigator.userAgent.toLowerCase().match(/MicroMessenger/i)) ===
        "micromessenger"
      );
    },
  },
  mounted() {
    // this.queryConfig();
  },
  methods: {
    gotoBAJ() {
      window.open("https://beian.miit.gov.cn/#/Integrated/index");
    },
    async queryConfig() {
      const resp = await this.$axios.post(
        `${window.location.origin}/server/BizView.queryBizRuleAll.query`,
        { params: {} }
      );
      if (
        resp.status == 200 &&
        resp.data &&
        resp.data.data &&
        resp.data.data.AppVersion
      ) {
        this.config = resp.data.data;
      }
    },
    download(type) {
      if (!this.config) {
        this.queryConfig();
        return;
      }
      let apkUrl = this.config.AppVersion.Android.url;
      if (type == "chat") {
        apkUrl =
          this.config.ChatVersion &&
          this.config.ChatVersion.Android &&
          this.config.ChatVersion.Android.url;
      } else if (type == "yaosu") {
        apkUrl =
          this.config.SmileVersion &&
          this.config.SmileVersion.Android &&
          this.config.SmileVersion.Android.url;
      } else if (type == "zsg") {
        apkUrl =
          this.config.MallVersion &&
          this.config.MallVersion.Android &&
          this.config.MallVersion.Android.url;
      }
      window.open(apkUrl);
    },
    isWechat() {},
  },
};
</script>

<style scoped>
.bg {
  object-fit: cover;
}
</style>
